$border-radius: 2px;

$min-width: 1020px;
$min-width-content: 940px;

$time-function: ease-in-out;
$time-short: 0.1s;
$time-medium: 0.3s;
$time-long: 0.5s;

$font-weight-regular: 400;
$font-weight-bold: 700;

$clr-white: #ffffff;
$clr-black: #000000;
$clr-black-1: #474747;
$clr-black-2: rgba(63 63 63 / 20%);
$clr-gray-1: rgba(30  9 6 / 15%);
$clr-gray-2: #9f9e9e;
$clr-gray-3: rgba(0 0 0 / 60%);
$clr-gray-4: #585757;
$clr-gray-5: #c1c1c1;
$clr-gray-6: #3f3f3f;
$clr-gray-7: #dddada;
$clr-gray-8: #dddddd;
$clr-gray-9: #f7f3f3;
$clr-gray-10: #6c6c6c;
$clr-gray-11: #d9d9d9;
$clr-gray-12: rgba(88 87 87 / 60%);
$clr-gray-13: rgba(88 87 87 / 70%);
$clr-orange-1: #e27f00;
$clr-orange-2: #ffd168;
$clr-orange-3: #ffa839;
$clr-orange-4: #da8b26;
$clr-orange-5: #f20505;
$clr-green-1: #68d136;
