@use "./variables" as var;

@mixin grid() {
  box-sizing: border-box;
  width: 100%;
  padding-right: calc((100% - var.$min-width-content) / 2);
  padding-left: calc((100% - var.$min-width-content) / 2);
}

@mixin visually-hidden() {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);

  clip-path: inset(100%);
}
