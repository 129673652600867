@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;

.cart-list {
  margin-top: 27px;
}

.cart-list__title {
  @include mix.visually-hidden();
}

.cart-list__items {
  display: flex;
  flex-direction: column;
}

.cart-list__info {
  @include fonts-mix.font-20-15-bold();

  margin: 50px 0;

  text-align: center;
}
