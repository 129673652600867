@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;
@use "../../styles/_mixins" as mix;

.checkbox {
  @include fonts-mix.font-16-19();

  --shadow-color: #{var.$clr-gray-2};

  & + & {
    margin-top: 12px;
  }
}

.checkbox__input {
  @include mix.visually-hidden();

  &:hover + .checkbox__content {
    --shadow-color: #{var.$clr-gray-4};
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    & + .checkbox__content {
      --shadow-color: #{var.$clr-gray-5};

      // stylelint-disable-next-line max-nesting-depth
      &::before {
        transition: none;
      }
    }
  }

  &:checked + .checkbox__content {
    &::after {
      opacity: 1;
    }
  }

  &:disabled + .checkbox__content {
    cursor: default;
    pointer-events: none;

    &::before {
      --shadow-color: #{var.$clr-gray-8};

      background-color: var.$clr-gray-9;

      transition: none;
    }

    &::after {
      opacity: 0;
    }
  }
}

.checkbox__content {
  $min-size: 24px;

  position: relative;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: $min-size;
  padding: 0;
  padding-left: 40px;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: $min-size;
    height: $min-size;
    overflow: hidden;

    border: none;
    border-radius: var.$border-radius;
    box-shadow: inset 0 0 0 0.7px var(--shadow-color);
    transition: box-shadow var.$time-medium var.$time-function;
  }

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: $min-size;
    height: $min-size;
    background-image: url("./img/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0;
  }
}
