@use "../../styles/_variables" as var;
@use "../../styles/_mixins" as mix;
@use "../../styles/fonts-mixins" as fonts-mix;

.page-404 {
  @include mix.grid();
}

.page-404__title {
  @include fonts-mix.font-16-19-narrow();
}

.page-404__pages-list {
  margin-top: 20px;
}

.page-404__page-wrapper:not(:first-child) {
  margin-top: 10px;
}

.page-404__page-link {
  @include fonts-mix.font-14-20(var.$clr-orange-1);

  display: block;

  opacity: 1;

  transition: color var.$time-medium var.$time-function;

  &:hover {
    color: var.$clr-black-1;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-black-1;

    opacity: 0.5;

    transition: none;
  }
}
