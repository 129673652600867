@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.button-action {
  @include fonts-mix.font-16-15-bold(var.$clr-white);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 220px;
  padding: 9px 8px;

  text-align: center;

  background-color: var.$clr-orange-1;
  border: 1px solid var.$clr-orange-1;
  border-radius: var.$border-radius;

  transition:
    background-color var.$time-medium var.$time-function,
    border-color var.$time-medium var.$time-function;

  &:hover {
    background-color: var.$clr-gray-4;
    border-color: var.$clr-gray-4;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    background-color: var.$clr-gray-2;
    border-color: var.$clr-gray-2;

    transition: none;
  }

  &--ghost {
    color: var.$clr-black;

    opacity: 1;

    transition: opacity var.$time-medium var.$time-function;

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: var.$clr-white;
      border-color: var.$clr-gray-4;
    }

    &:hover {
      opacity: 0.7;
    }

    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
}
