@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.button-action-small {
  @include fonts-mix.font-12-15-bold(var.$clr-white);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 86px;
  padding: 4px 10px;

  background-color: var.$clr-gray-5;
  border: none;
  border-radius: var.$border-radius;

  transition: background-color var.$time-medium var.$time-function;

  &:hover {
    background-color: var.$clr-gray-4;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    background-color: var.$clr-gray-2;

    transition: none;
  }
}
