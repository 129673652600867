@use "../../styles/fonts-mixins" as fonts-mix;
@use "../../styles/variables" as var;

.filter {
  grid-area: filter;
}

.filter__title {
  @include fonts-mix.font-18-21-bold();
}

.filter__block {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 29px;
  padding: 0;
  border: none;

  &--price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.filter__block-title {
  @include fonts-mix.font-18-21-bold();

  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 24px;
  border: none;
  border-top: 0.7px solid var.$clr-gray-7;
}

.filter__input {
  @include fonts-mix.font-14-20();

  display: block;
  width: 84px;
  height: 28px;
  padding: 0;
  padding-left: 14px;
  overflow: hidden;

  text-align: center;

  border: 0.7px solid var.$clr-gray-2;
  border-radius: var.$border-radius;

  transition: border-color var.$time-medium var.$time-function;

  &::placeholder {
    color: var.$clr-gray-3;
  }

  &:hover {
    border-color: var.$clr-gray-1;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    border-color: var.$clr-gray-4;

    transition: none;
  }
}

.filter__checkbox {
  margin-right: auto;
}

.filter__submit {
  @include fonts-mix.font-12-14-bold-up(var.$clr-white);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-right: auto;
  padding: 8px 24px;
  overflow: hidden;

  background-color: var.$clr-gray-5;
  border-radius: var.$border-radius;

  transition: background-color var.$time-medium var.$time-function;

  &:hover {
    background-color: var.$clr-gray-4;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    background-color: var.$clr-gray-2;

    transition: none;
  }
}
