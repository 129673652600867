@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.catalog-page-button {
  &:not(:last-of-type) {
    margin-right: 10px;
  }

  &--text:not(:last-of-type) {
    margin-right: 14px;
  }

  &--text:last-of-type {
    margin-left: 4px;
  }
}

.catalog-page-button__button {
  @include fonts-mix.font-12-20();

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  min-height: 24px;
  padding: 1px 5px;
  overflow: hidden;

  border: 0.7px solid var.$clr-gray-2;
  border-radius: var.$border-radius;

  transition:
    color var.$time-medium var.$time-function,
    background-color var.$time-medium var.$time-function;

  &:hover {
    color: var.$clr-white;

    background-color: var.$clr-gray-2;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-white;

    background-color: var.$clr-gray-4;
    border-color: var.$clr-gray-4;

    transition: none;
  }

  &--active {
    color: var.$clr-white;

    background-color: var.$clr-gray-4;

    cursor: default;
    pointer-events: none;
  }
}

.catalog-page-button--text .catalog-page-button__button {
  padding-right: 21px;
  padding-left: 21px;
}
