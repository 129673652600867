@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.guitar-info-block {
  position: relative;

  display: grid;
  gap: 0;
  grid-template-areas:
    "img title"
    "img article"
    "img info"
    "img price";
  grid-template-columns: 96px 260px;
  grid-template-rows: min-content min-content min-content 1fr;
  box-sizing: border-box;
}

.guitar-info-block__img {
  display: block;
  align-self: start;
  justify-self: start;
  grid-area: img;
  width: auto;
  max-width: 80%;
  height: 128px;
  margin-right: 25px;

  object-fit: contain;
}

.guitar-info-block__title {
  @include fonts-mix.font-16-15-bold-up();

  grid-area: title;
}

.guitar-info-block__article {
  @include fonts-mix.font-14-15();

  grid-area: article;
  margin-top: 14px;
}

.guitar-info-block__info {
  @include fonts-mix.font-14-15();

  grid-area: info;
  margin-top: 6px;
}

.guitar-info-block__price {
  @include fonts-mix.font-20-15-bold();

  grid-area: price;
  margin-top: 20px;
}
