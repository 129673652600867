@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.catalog-item {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  box-sizing: border-box;
  min-height: 304px;
  padding: 16px 13px 19px 14px;
  border: 1px solid var.$clr-gray-7;
}

.catalog-item__img {
  display: block;
  width: auto;
  height: 190px;
  margin: 0 auto;
}

.catalog-item__rate {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 6px;

  @for $i from 1 through 5 {
    &--#{$i} {
      path:nth-of-type(-n + #{$i}) {
        fill: var.$clr-orange-2;
      }
    }
  }
}

.catalog-item__reviews-number {
  @include fonts-mix.font-poppins-10-15(var.$clr-gray-2);

  margin-left: 3px;
}

.catalog-item__title {
  @include fonts-mix.font-14-15();

  display: block;
  margin-top: 5px;
  margin-right: auto;
  padding-right: 16px;
}

.catalog-item__price {
  @include fonts-mix.font-14-15();

  display: block;
  margin-top: 5px;
}

.catalog-item__more-button.button-action-small {
  min-width: 69px;
  margin-top: 13px;
  margin-right: 16px;
  margin-left: -1px;
}

.catalog-item__buy-button.button-action-small {
  margin-top: 13px;

  &::before {
    content: "";

    display: block;
    width: 12px;
    height: 12px;
    margin-right: 4px;

    background-image: url("./img/cart.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &.button-action-small {
    padding-left: 5px;

    background-color: var.$clr-orange-1;

    &:hover,
    &:focus {
      background-color: var.$clr-orange-3;
    }

    &:active {
      background-color: var.$clr-orange-4;
    }
  }
}
