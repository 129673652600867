@use "../../styles/_variables" as var;
@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;

.cart-order-panel {
  display: grid;
  grid-template-areas:
    "discount-title total-price"
    "discount-description total-price"
    "discount-form order-button";
  grid-template-columns: 460px 460px;
  row-gap: 0;
  margin-top: 40px;
  column-gap: 20px;
}

.cart-order-panel__title {
  @include mix.visually-hidden();
}

.cart-order-panel__discount-title {
  @include fonts-mix.font-16-15-bold();

  grid-area: discount-title;
}

.cart-order-panel__discount-info {
  @include fonts-mix.font-12-15-narrow();

  grid-area: discount-description;
  margin-top: 10px;

  &--success {
    color: var.$clr-green-1;
  }

  &--error {
    color: var.$clr-orange-5;
  }
}

.cart-order-panel__discount-form {
  display: flex;
  flex-wrap: wrap;
  grid-area: discount-form;
}

.cart-order-panel__discount-input {
  @include fonts-mix.font-16-15();

  display: block;
  box-sizing: border-box;
  width: 170px;
  height: 36px;
  margin-top: 19px;
  margin-right: 33px;
  padding: 8px 10px;

  text-align: center;
  text-transform: uppercase;

  border: 2px solid var.$clr-gray-7;
  border-radius: var.$border-radius;
}

.cart-order-panel__discount-apply-button {
  justify-content: flex-start;
  min-width: 200px;
  margin-top: 19px;
  padding-left: 22px;
}

.cart-order-panel__total-price {
  @include fonts-mix.font-20-15-bold();

  justify-self: end;
  grid-area: total-price;
  padding-right: 5px;

  & > span {
    text-decoration: line-through;
  }
}

.cart-order-panel__submit-order-button {
  align-self: start;
  grid-area: order-button;
  margin-top: 19px;
  margin-left: auto;
}
