@use "./variables" as var;

@font-face {
  font-weight: var.$font-weight-regular;
  font-family: "Droid Sans";
  font-style: normal;
  src:
    url("../fonts/DroidSans-Regular.woff2") format("woff2"),
    url("../fonts/DroidSans-Regular.woff") format("woff"),
    url("../fonts/DroidSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-weight: var.$font-weight-bold;
  font-family: "Droid Sans";
  font-style: normal;
  src:
    url("../fonts/DroidSans-Bold.woff2") format("woff2"),
    url("../fonts/DroidSans-Bold.woff") format("woff"),
    url("../fonts/DroidSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-weight: var.$font-weight-regular;
  font-family: "Poppins";
  font-style: normal;
  src:
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff"),
    url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}
