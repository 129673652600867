@use "../../styles/_variables" as var;
@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;

.cart-list-item {
  position: relative;

  display: grid;
  align-items: start;
  gap: 0;
  grid-template-areas: "info-block item-price counter total-price";
  grid-template-columns: 1fr 200px 140px 140px;
  box-sizing: border-box;
  min-height: 164px;
  padding: 20px 0 19px 46px;

  border: none;
  border-top: 0.7px solid var.$clr-gray-7;

  &:last-of-type {
    border-bottom: 0.7px solid var.$clr-gray-7;
  }
}

.cart-list-item__delete-button {
  position: absolute;
  top: 20px;
  left: 0;
}

.cart-list-item__info-block.guitar-info-block {
  grid-template-columns: 116px 1fr;
  padding-right: 20px;

  .guitar-info-block__img {
    height: 124px;
  }
}

.cart-list-item__price {
  @include fonts-mix.font-20-15();

  align-self: center;
  justify-self: center;
  grid-area: item-price;
  padding: 7px 20px 0;

  text-align: center;
}

.cart-list-item__counter-wrapper {
  @include fonts-mix.font-16-15(var.$clr-gray-2);

  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: start;
  justify-self: center;
  grid-area: counter;
  margin: 20px 0 0;

  border: 1px solid var.$clr-gray-2;
}

.cart-list__counter-button {
  position: relative;

  width: 26px;
  height: 28px;

  transition: background-color var.$time-medium var.$time-function;

  &:hover {
    background-color: var.$clr-gray-2;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    background-color: var.$clr-gray-1;

    transition: none;
  }

  &--sub,
  &--add {
    &::before,
    &::after {
      content: "";

      position: absolute;
      top: 50%;
      left: 50%;

      display: block;
      width: 8px;
      height: 1px;

      background-color: var.$clr-gray-2;

      transform: translate(-50%, -50%);

      transition: background-color var.$time-medium var.$time-function;
    }

    &:hover,
    &:active,
    &:focus {
      // stylelint-disable-next-line max-nesting-depth
      &::before,
      &::after {
        background-color: var.$clr-white;
      }
    }

    &:focus {
      // stylelint-disable-next-line max-nesting-depth
      &::before,
      &::after {
        outline: none;

        transition: none;
      }
    }
  }

  &--sub::after {
    content: none;
  }

  &--add::after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.cart-list-item__counter {
  display: flex;
  align-self: stretch;
  padding: 9px 8px 2px;

  border: 1px solid var.$clr-gray-2;
  border-top: none;
  border-bottom: none;
}

.cart-list-item__total-price {
  @include fonts-mix.font-20-15-bold();

  align-self: center;
  justify-self: end;
  grid-area: total-price;
  margin-top: 6px;
  padding-left: 20px;
}
