@use "../../styles/_fonts-mixins" as fonts-mix;

.popup-guitar-info__content {
  display: grid;
  gap: 0;
  grid-template-columns: 356px 1fr;
}

.popup-guitar-info__title {
  @include fonts-mix.font-18-15-bold();

  grid-column: 1 / -1;
}

.popup-guitar-info__info-block {
  grid-column: 1 / 2;
  margin-top: 27px;

  .guitar-info-block__img {
    margin-left: 5px;
  }

  .guitar-info-block__title {
    margin-top: 14px;
  }

  .guitar-info-block__article,
  .guitar-info-block__info {
    margin-left: 2px;
  }
}

.popup-guitar-info__add-cart-button {
  align-self: start;
  grid-column: 2 / 3;
  margin-top: 40px;
  margin-left: auto;

  &.button-action {
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 3px;
  }
}
