@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;
@use "../../styles/_media" as media;

.main-title {
  @include mix.grid();
  @include fonts-mix.font-22-26-bold();

  position: relative;

  padding-top: 213px;

  &::before {
    content: "";

    position: absolute;
    top: 24px;
    left: 0;

    display: block;
    width: 100%;
    height: 74px;

    background-image: url("./img/guitar-lines.svg");
    background-repeat: repeat, no-repeat;
  }

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    width: 894px;
    height: 298px;

    background-image: url("../../img/guitar.png");
    background-repeat: no-repeat;
    background-size: contain;

    transform: translate(-316px, -38px);

    @media #{media.$retina} {
      background-image: url("../../img/guitar@2x.png");
    }
  }
}

.main-header--cart .main-title {
  padding-top: 238px;
}
