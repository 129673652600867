@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;

.catalog {
  display: block;
  grid-area: catalog;
  width: 100%;
}

.catalog__title {
  @include mix.visually-hidden();
}

.catalog__info {
  @include fonts-mix.font-16-19();

  margin: 60px 0;

  text-align: center;
}

.catalog__items-list {
  display: grid;
  grid-template-columns: repeat(3, 220px);
  row-gap: 30px;
  column-gap: 20px;
}

.catalog__page-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
