@use "../../styles/_variables" as var;
@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;

.main-header {
  position: relative;

  display: block;
  width: 100%;
  min-height: 370px;
  overflow: hidden;
}

.main-header__nav {
  @include mix.grid();

  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  background-color: var.$clr-gray-1;
}

.main-header__logo {
  display: block;
  width: auto;
  height: 70px;

  color: var.$clr-black;
}

.main-header__links {
  display: flex;
  flex-wrap: wrap;
  margin-left: 114px;
}

.main-header__link-wrapper {
  margin-right: 40px;
}

.main-header__link {
  @include fonts-mix.font-14-20();

  display: block;

  opacity: 1;

  transition: color var.$time-medium var.$time-function;

  &:hover {
    color: var.$clr-black-1;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-black-1;

    opacity: 0.5;

    transition: none;
  }

  &--current {
    cursor: default;

    pointer-events: none;
  }
}

.main-header__tools {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  padding-right: 4px;
}

.main-header__tool-wrapper {
  margin-left: 10px;
}

.main-header__tool {
  position: relative;

  display: block;
  width: 40px;
  height: 40px;

  color: var.$clr-black;

  & > svg {
    display: block;
    width: 100%;
    height: auto;

    opacity: 1;

    transition: color var.$time-medium var.$time-function;
  }

  &:hover {
    & > svg {
      color: var.$clr-black-1;
    }
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    & > svg {
      color: var.$clr-black-1;

      opacity: 0.5;

      transition: none;
    }
  }

  &--current {
    cursor: default;

    pointer-events: none;
  }
}

.main-header__tool-cart-counter {
  @include fonts-mix.font-10-12(var.$clr-orange-5);

  position: absolute;
  top: 10px;
  left: 100%;

  transform: translateX(-12px);
}

.main-header__breadcrumbs {
  @include mix.grid();

  display: flex;
  flex-wrap: wrap;
}

.main-header__breadcrumb-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:not(:last-of-type)::after {
    content: "";

    display: block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 10px;

    background-image: url("./img/arrow.svg");
    background-repeat: no-repeat;
  }
}

.main-header__breadcrumb {
  @include fonts-mix.font-16-19(var.$clr-black-1);

  display: block;

  opacity: 1;

  transition: color var.$time-medium var.$time-function;

  &:hover {
    color: var.$clr-gray-2;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-gray-2;

    opacity: 0.5;

    transition: none;
  }

  &--current {
    cursor: default;

    pointer-events: none;
  }
}
