@use "../../styles/_variables" as var;
@use "../../styles/_mixins" as mix;
@use "../../styles/_fonts-mixins" as fonts-mix;
@use "../../styles/_media" as media;

.main-footer {
  position: relative;

  display: block;
  width: 100%;
  margin-top: 35px;
  padding-top: 104px;
  overflow: hidden;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    width: 894px;
    height: 298px;

    background-image: url("../../img/guitar.png");
    background-repeat: no-repeat;
    background-size: contain;

    transform: rotate(180deg) translate(584px, -15px);

    @media #{media.$retina} {
      background-image: url("../../img/guitar@2x.png");
    }
  }

  &::after {
    content: "";

    position: absolute;
    top: 88px;
    left: 0;

    display: block;
    width: 100%;
    height: 6px;

    background-color: var.$clr-gray-6;
  }

  &--cart {
    margin-top: 66px;
  }
}

.main-footer__content-wrapper {
  @include mix.grid();

  position: relative;

  display: grid;
  gap: 0;
  grid-template-areas:
    "logo about catalog nav contacts"
    "connects about catalog nav contacts";
  grid-template-columns: 1fr 159px 163px 236px 140px;
  grid-template-rows: 70px 1fr;
  padding-top: 61px;
  padding-bottom: 60px;

  background-color: var.$clr-gray-6;
}

.main-footer__logo {
  display: block;
  grid-area: logo;
  box-sizing: border-box;
  width: 67px;
  height: 70px;
  padding-right: 20px;

  color: var.$clr-white;
}

.main-footer__connect-links {
  display: flex;
  grid-area: connects;
  box-sizing: border-box;
  margin-top: 31px;
}

.main-footer__connect-link-wrapper {
  display: block;
  width: 24px;
  height: 24px;
  margin-top: 24px;

  &:not(:last-of-type) {
    margin-right: 24px;
  }
}

.main-footer__connect-link {
  display: block;
  width: 100%;
  height: 100%;

  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active,
  &:focus {
    opacity: 0.3;

    transition: none;
  }

  &:focus {
    outline: none;
  }
}

.main-footer__block {
  box-sizing: border-box;
  padding: 6px 0 13px;

  &--about {
    grid-area: about;
    padding-right: 23px;
  }

  &--catalog {
    grid-area: catalog;
    padding-right: 9px;
  }

  &--info {
    grid-area: nav;
    padding-right: 93px;
  }

  &--contacts {
    grid-area: contacts;
  }
}

.main-footer__block-title {
  @include fonts-mix.font-14-44(var.$clr-white);

  opacity: 0.7;
}

.main-footer__block-paragraph {
  @include fonts-mix.font-10-12(var.$clr-white);

  display: block;

  & a[href^="tel"] {
    display: flex;

    white-space: nowrap;

    &::before {
      content: "";

      display: block;
      width: 10px;
      height: 10px;
      margin-right: 5px;

      background-image: url("./img/phone.svg");
    }

    &:hover {
      opacity: 0.7;
    }

    &:active,
    &:focus {
      opacity: 0.3;

      transition: none;
    }

    &:focus {
      outline: none;
    }
  }

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  &--narrow {
    @include fonts-mix.font-10-12-narrow(var.$clr-white);
  }
}

.main-footer__block-clock {
  display: flex;

  &::before {
    content: "";

    display: block;
    width: 10px;
    height: 10px;
    margin-right: 5px;

    background-image: url("./img/clock.svg");
  }
}
