@use "../../styles/fonts-mixins" as fonts-mix;

.sort {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-area: sort;
  padding-right: 8px;
}

.sort__title {
  @include fonts-mix.font-16-19-narrow();

  margin-right: 19px;
}

.sort__type-button {
  margin-right: 34px;

  text-transform: lowercase;

  & + .sort__direction-button {
    margin-left: auto;
  }
}

.sort__direction-button {
  &:not(:last-of-type) {
    margin-right: 14px;
  }
}
