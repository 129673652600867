@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.sort-type-button {
  @include fonts-mix.font-16-19-narrow(var.$clr-gray-4);

  &:hover {
    color: var.$clr-gray-6;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-gray-7;
  }

  &--active,
  &:disabled {
    color: var.$clr-black;

    cursor: default;
    pointer-events: none;
  }
}
