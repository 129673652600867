@use "../../styles/_variables" as var;

.button-close {
  display: block;
  width: 20px;
  height: 20px;

  &::before,
  &::after {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 15px;
    height: 1.4px;

    background-color: var.$clr-gray-11;

    transform-origin: center;

    transition: background-color var.$time-medium var.$time-function;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: var.$clr-gray-4;
    }
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    &::before,
    &::after {
      background-color: var.$clr-gray-2;

      transition: none;
    }
  }
}
