@use "../../styles/_mixins" as mix;

.app {
  position: relative;

  display: grid;
  gap: 0;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr min-content;
  width: 100%;
  height: 100%;
}
