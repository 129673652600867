@use "../../styles/_variables" as var;

.popup {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-width: var.$min-width;
  height: 100%;
  padding: 60px 20px;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: var.$clr-gray-12;

  cursor: pointer;
}

.popup__content {
  position: relative;

  display: block;
  box-sizing: border-box;
  width: 740px;
  margin: auto;
  padding: 30px 50px;
  overflow: hidden;

  background-color: var.$clr-white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 5px 6px var.$clr-black-2;

  cursor: default;
}

.popup__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  left: auto;
}
