@use "../../styles/_variables" as var;

.sort-direction-button {
  display: block;
  width: 22px;
  height: 22px;

  color: var.$clr-gray-10;

  & svg {
    display: block;
    width: 100%;
    height: auto;
    transition: color var.$time-medium var.$time-function;
  }

  &:hover {
    color: var.$clr-gray-6;
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    color: var.$clr-gray-7;

    & svg {
      transition: none;
    }
  }

  &--descending {
    transform: rotate(180deg);
  }

  &--active {
    color: var.$clr-black;

    cursor: default;
    pointer-events: none;
  }
}
