@use "./fonts.scss" as *;
@use "./_variables.scss" as var;
@use "./_mixins" as mix;

html {
  height: 100%;
  overflow-y: scroll;

  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-text-size-adjust: 100%;

  &[data-scroll-hidden] {
    overflow: hidden;
  }
}

body {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  min-width: var.$min-width;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;

  font-family: "Droid Sans", "Tahoma", sans-serif;
  color: var.$clr-black;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: var.$clr-black;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  display: block;
  margin: 0;

  padding: 0;

  list-style: none;
}

button,
input,
textarea {
  box-sizing: border-box;
  padding: 0;

  background: transparent;

  border: none;

  box-shadow: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:not([href]),
  &:disabled {
    cursor: default;

    pointer-events: none;
  }
}

button {
  margin: 0;
  padding: 0;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  color: inherit;
  font-style: inherit;

  border: none;
  cursor: pointer;

  &:disabled {
    cursor: default;

    pointer-events: none;
  }
}

p {
  font-family: inherit;
}

label {
  font-family: inherit;

  cursor: pointer;
}

input {
  font-family: inherit;

  color: inherit;
}

textarea {
  font-family: inherit;

  resize: none;
}

figure {
  margin: 0;
}

iframe {
  border: none;
}

/*

    Classes

*/

.visually-hidden:not(:focus):not(:active),
input[type="range"].visually-hidden,
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  @include mix.visually-hidden();
}
