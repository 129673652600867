@use "../../styles/_variables" as var;
@use "../../styles/_fonts-mixins" as fonts-mix;

.main-footer__block-link {
  @include fonts-mix.font-10-20(var.$clr-white);

  display: block;

  &:hover {
    opacity: 0.7;
  }

  &:active,
  &:focus {
    opacity: 0.3;

    transition: none;
  }

  &:focus {
    outline: none;
  }

  &--current {
    cursor: default;

    pointer-events: none;
  }
}
