@use "../../styles/_fonts-mixins" as fonts-mix;

.popup-delete-cart__content {
  display: grid;
  gap: 0;
  grid-template-areas:
    "title title"
    "info delete-button"
    "info cansel-button"
    "info .";
  grid-template-columns: 356px 1fr;
  grid-template-rows: min-content min-content min-content 1fr;
}

.popup-delete-cart__title {
  @include fonts-mix.font-18-15-bold();

  grid-area: title;
}

.popup-delete-cart__info-block {
  grid-area: info;
  margin-top: 25px;

  .guitar-info-block__img {
    margin-left: 5px;
  }

  .guitar-info-block__title {
    margin-top: 14px;
  }

  .guitar-info-block__article,
  .guitar-info-block__info {
    margin-left: 2px;
  }
}

.popup-delete-cart__delete-button {
  justify-self: end;
  grid-area: delete-button;
  margin-top: 39px;

  &.button-action {
    padding-top: 12px;
    padding-bottom: 17px;
    padding-left: 2px;
  }
}

.popup-delete-cart__cansel-button {
  justify-self: end;
  grid-area: cansel-button;
  margin-top: 16px;

  &.button-action {
    padding: 14px 12px 15px 3px;
  }
}
