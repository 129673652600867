@use "../../styles/_variables" as var;

.logo-link {
  display: block;

  svg {
    display: block;
    width: auto;
    height: 100%;

    opacity: 1;

    transition: color var.$time-medium var.$time-function;
  }

  &:hover {
    & > svg {
      color: var.$clr-gray-2;
    }
  }

  &:focus {
    outline: none;
  }

  &:active,
  &:focus {
    & > svg {
      color: var.$clr-gray-2;

      opacity: 0.5;

      transition: none;
    }
  }

  &--current {
    cursor: default;

    pointer-events: none;
  }
}
