@use "../../styles/_mixins" as mix;

.page-catalog {
  @include mix.grid();

  position: relative;

  display: grid;
  grid-template-areas:
    "filter sort"
    "filter catalog";
  grid-template-columns: 208px 1fr;
  grid-template-rows: min-content 1fr;
  row-gap: 29px;
  width: 100%;
  padding-top: 49px;
  column-gap: 32px;
}
