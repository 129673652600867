@use "../../styles/_fonts-mixins" as fonts-mix;

.popup-success-catalog__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.popup__content {
    padding-bottom: 54px;
  }
}

.popup-success-catalog__title {
  @include fonts-mix.font-18-15-bold();

  width: 100%;
}

.popup-success-catalog__transition-button {
  margin-top: 40px;
  margin-right: 38px;

  &.button-action {
    padding-top: 12px;
    padding-bottom: 17px;
    padding-left: 3px;
  }
}

.popup-success-catalog__continue-button {
  margin-top: 40px;

  &.button-action {
    padding-top: 14px;
    padding-bottom: 15px;
    padding-left: 3px;
  }
}
