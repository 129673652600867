@use "./variables" as var;

// Droid Sans

@mixin font-10-12($clr: var.$clr-black) {
  $font-size: 10;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(12 / $font-size);
  color: $clr;
}

@mixin font-10-12-narrow($clr: var.$clr-black) {
  @include font-10-12($clr);

  letter-spacing: 0.1em;
}

@mixin font-10-20($clr: var.$clr-black) {
  $font-size: 10;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(20 / $font-size);
  color: $clr;
  letter-spacing: 0.01em;
}

@mixin font-12-14-bold-up($clr: var.$clr-black) {
  $font-size: 12;

  font-weight: var.$font-weight-bold;
  font-size: calc($font-size * 1px);
  line-height: calc(14 / $font-size);
  color: $clr;
  text-transform: uppercase;
}

@mixin font-12-15($clr: var.$clr-black) {
  $font-size: 12;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  color: $clr;
}

@mixin font-12-15-narrow($clr: var.$clr-black) {
  @include font-12-15($clr);

  letter-spacing: 0.05em;
}

@mixin font-12-15-bold($clr: var.$clr-black) {
  @include font-12-15($clr);

  font-weight: var.$font-weight-bold;
  letter-spacing: 0.02em;
}

@mixin font-12-20($clr: var.$clr-black) {
  $font-size: 12;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(20 / $font-size);
  color: $clr;
  letter-spacing: 0.05em;
}

@mixin font-14-15($clr: var.$clr-black) {
  $font-size: 14;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  color: $clr;
  letter-spacing: 0.05em;
}

@mixin font-14-20($clr: var.$clr-black) {
  $font-size: 14;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(20 / $font-size);
  color: $clr;
  letter-spacing: 0.05em;
}

@mixin font-14-44($clr: var.$clr-black) {
  $font-size: 14;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(44 / $font-size);
  color: $clr;
  letter-spacing: 0.1em;
}

@mixin font-16-15($clr: var.$clr-black) {
  $font-size: 16;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  color: $clr;
  letter-spacing: 0.05em;
}

@mixin font-16-15-bold($clr: var.$clr-black) {
  @include font-16-15($clr);

  font-weight: var.$font-weight-bold;
}

@mixin font-16-15-bold-up($clr: var.$clr-black) {
  @include font-16-15-bold($clr);

  text-transform: uppercase;
}

@mixin font-16-19($clr: var.$clr-black) {
  $font-size: 16;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(19 / $font-size);
  color: $clr;
}

@mixin font-16-19-narrow($clr: var.$clr-black) {
  @include font-16-19($clr);

  letter-spacing: 0.05em;
}

@mixin font-18-15-bold($clr: var.$clr-black) {
  $font-size: 18;

  font-weight: var.$font-weight-bold;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  color: $clr;
  letter-spacing: 0.02em;
}

@mixin font-18-21-bold($clr: var.$clr-black) {
  $font-size: 18;

  font-weight: var.$font-weight-bold;
  font-size: calc($font-size * 1px);
  line-height: calc(21 / $font-size);
  color: $clr;
}

@mixin font-20-15($clr: var.$clr-black) {
  $font-size: 20;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  color: $clr;
  letter-spacing: 0.05em;
}

@mixin font-20-15-bold($clr: var.$clr-black) {
  @include font-20-15($clr);

  font-weight: var.$font-weight-bold;
}

@mixin font-22-26-bold($clr: var.$clr-black) {
  $font-size: 22;

  font-weight: var.$font-weight-bold;
  font-size: calc($font-size * 1px);
  line-height: calc(26 / $font-size);
  color: $clr;
}

// Poppins

@mixin font-poppins-10-15($clr: var.$clr-black) {
  $font-size: 10;

  font-weight: var.$font-weight-regular;
  font-size: calc($font-size * 1px);
  line-height: calc(15 / $font-size);
  font-family: "Poppins", "Tahoma", sans-serif;
  color: $clr;
  letter-spacing: 0.1em;
}
